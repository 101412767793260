import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        initImmediate: false,
        debug: false,
        fallbackLng: 'en',
        load: 'languageOnly',
        backend: {
            loadPath: '/i18n/{{ns}}/{{lng}}.json',
        },
        ns: ['general'],
        defaultNS: 'general',
        react: {
            wait: true,
        },
    });

export default i18n