import React from 'react';
import Avatar from './Avatar';
import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import {Translation} from 'react-i18next';
import {useKeycloak} from '@react-keycloak/web';
import Loader from './Loader';

const UserDropdown = () => {
    const [profile, setProfile] = React.useState("");
    const [keycloak, initialized] = useKeycloak();

    const fetchUserProfile = async() => {
        // Here you can access all of keycloak methods and variables.
        // See https://www.keycloak.org/docs/latest/securing_apps/index.html#javascript-adapter-reference
        const profile = await keycloak.loadUserProfile();
        setProfile(profile);
    };

    if (initialized === true) {
        fetchUserProfile();
    }

    return (
        <Dropdown>
            <Dropdown.Toggle variant="default" id="user-dropdown" className="text-light">
                {profile
                    ? <Avatar profile={profile} size={40}/>
                    : <Loader className="d-inline"/>
                }
                {profile && <span className="ml-2 d-none d-lg-inline">{profile.firstName} {profile.lastName}</span>}
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight={true}>
                {profile &&
                <div>
                    <Dropdown.Header>
                        <Table borderless size="sm" className="m-0 text-muted">
                            <tr className="d-lg-none">
                                <td className="pb-0"><i className="fas fa-user"/></td>
                                <td className="pb-0">{profile.firstName} {profile.lastName}</td>
                            </tr>
                            <tr>
                                <td className="pb-0"><i className="fas fa-at"/></td>
                                <td className="pb-0">{profile.username}</td>
                            </tr>
                            <tr>
                                <td><i className="fas fa-envelope"/></td>
                                <td>{profile.email}</td>
                            </tr>
                        </Table>
                    </Dropdown.Header>
                    <Dropdown.Divider />
                </div>
                }
                <Dropdown.Item eventKey="1" onClick={() => keycloak.accountManagement()}>
                    <Translation>{(t, {i18n}) => <span>{t('account.edit')}</span>}</Translation>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="2" onClick={() => keycloak.logout()}>
                    <Translation>{(t, {i18n}) => <span>{t('account.logout')}</span>}</Translation>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserDropdown;