import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {Translation} from 'react-i18next';
import {geolocated} from "react-geolocated";
import {orderByDistance} from 'geolib';
import UserAvatar from 'react-user-avatar';
import {withCookies, Cookies} from 'react-cookie';
import {Locations} from './Locations';
import {instanceOf} from 'prop-types';

class LocationSwitcher extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const {cookies} = props;

        let first = Locations[0].key;
        let key = cookies.get('location');
        let location = this.getLocationByKey(key || first);
        this.state = {location: location};
    }

    getLocationByKey(key) {
        let locations = Locations.filter(location => {
            return location.key === key
        });

        if (locations.length > 0) return locations[0];

        return null;
    }

    getLocations() {
        if (!this.props.isGeolocationAvailable || !this.props.coords) {
            return Locations;
        }

        return orderByDistance({
            latitude: this.props.coords.latitude,
            longitude: this.props.coords.longitude
        }, Locations);
    }

    changeLocation(key) {
        const {cookies} = this.props;
        cookies.set('location', key, {path: '/'});
        let location = this.getLocationByKey(key);
        this.setState({location: location});
    }

    render() {
        return (
            <Dropdown>
                <Translation>
                    {(t, {i18n}) => {
                        let name = t('location.city.' + this.state.location.city) + ' - ' + t('location.building.' + this.state.location.key);

                        return (
                            <Dropdown.Toggle variant="default" id="location-switcher" className="text-light">
                                <UserAvatar size="40" className="rounded-circle d-inline-block" color="#1d1d1b" name={name}/>
                                <span className="ml-2 d-none d-lg-inline">{name}</span>
                            </Dropdown.Toggle>
                        )
                    }}
                </Translation>
                <Dropdown.Menu alignRight={true}>
                    {this.getLocations().map((loc, index) => {
                        return (
                            <Translation key={index}>
                                {(t, {i18n}) =>
                                    <Dropdown.Item eventKey={index} onClick={() => this.changeLocation(loc.key)}>
                                        {t('location.city.' + loc.city)} - {t('location.building.' + loc.key)}
                                    </Dropdown.Item>
                                }
                            </Translation>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default withCookies(geolocated({
    watchPosition: true
})(LocationSwitcher));
