import React from 'react';
import {Translation} from 'react-i18next';
import iOSLogo from '../images/itunes-store.svg';
import androidLogo from '../images/google-play.svg';
import parse from 'html-react-parser';

class ChatSection extends React.Component {
    render() {
        const domain = 'chat.simplioffice.com';
        const iOSUrl = 'https://apps.apple.com/app/rocket-chat/id1148741252';
        const androidUrl = 'https://play.google.com/store/apps/details?id=chat.rocket.android';

        return (
            <Translation>
                {(t, {i18n}) =>
                    <section id="chat" className="text-center">
                        <i className="fas fa-comment-dots display-3"/>
                        <h3>{t('sections.chat.title')}</h3>
                        <p>
                            <a target="_blank" rel="noopener noreferrer" href={iOSUrl}><img src={iOSLogo} alt=""/></a>
                            <a target="_blank" rel="noopener noreferrer" href={androidUrl}><img src={androidLogo} alt=""/></a>
                        </p>
                        <p>{parse(t('sections.chat.description', {domain: domain}))}</p>
                    </section>
                }
            </Translation>
        );
    }
}

export default ChatSection;
