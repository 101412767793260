import React from 'react';
import Gravatar from 'react-gravatar';

class Avatar extends React.Component {
    render() {
        return (
            <Gravatar default="mm" email={this.props.profile ? this.props.profile.email : ''} size={this.props.size ? this.props.size : 40} className="rounded-circle"/>
        );
    }
}

export default Avatar;