import React from 'react';
import Keycloak from 'keycloak-js';
import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';
import {KeycloakProvider} from '@react-keycloak/web';
import {I18nextProvider} from 'react-i18next';
import {CookiesProvider} from 'react-cookie';
import Header from './Header';
import Footer from './Footer';
import Loader from './Loader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Navigation from './Navigation';
import HomePage from './pages/HomePage';
import HelpPage from './pages/HelpPage';
import LocationPage from './pages/LocationPage';
import i18n from './i18n';

const keycloak = new Keycloak({
    url: process.env.REACT_APP_URL,
    realm: process.env.REACT_APP_REALM,
    clientId: process.env.REACT_APP_CLIENT_ID
});
const keycloakProviderInitConfig = {
    checkLoginIframe: false,
    onLoad: 'login-required',
};

class App extends React.Component {
    render() {
        return (
            <KeycloakProvider keycloak={keycloak} initConfig={keycloakProviderInitConfig}>
                <I18nextProvider i18n={i18n}>
                    <CookiesProvider>
                        <BrowserRouter>
                            <React.Suspense fallback={Loader}>
                                <Header/>
                                <Row className="bg-light">
                                    <Col lg={2}>
                                        <Navigation/>
                                    </Col>
                                    <Col lg={8}>
                                        <Container className="pb-5 pt-5">
                                            <Switch>
                                                <Route path="/" exact component={HomePage}/>
                                                <Route path="/location" exact component={LocationPage}/>
                                                <Route path="/help" exact component={HelpPage}/>
                                                <Redirect to="/"/>
                                            </Switch>
                                        </Container>
                                    </Col>
                                    <Col lg={2}>
                                    </Col>
                                </Row>
                                <Footer/>
                            </React.Suspense>
                        </BrowserRouter>
                    </CookiesProvider>
                </I18nextProvider>
            </KeycloakProvider>
        );
    }
}

export default App;
