import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import UserDropdown from './UserDropdown';
import LanguageSelector from './LanguageSelector';
import LocationSwitcher from './LocationSwitcher';
import './header.scss';

class Header extends React.Component {
    render() {
        return (
            <header className="p-3 sticky-top">
                <Row>
                    <Col>
                        <Image src="/images/logos/picture-mark@1x.png" alt="Logo" className="d-md-none"
                               srcSet="/images/logos/picture-mark@1x.png 1x, /images/logos/picture-mark@2x.png 2x"/>
                        <Image src="/images/logos/word-mark@1x.png" alt="Logo" className="d-none d-md-inline"
                               srcSet="/images/logos/word-mark@1x.png 1x, /images/logos/word-mark@2x.png 2x"/>

                        <div className="float-right">
                            <UserDropdown/>
                        </div>
                        <div className="float-right">
                            <LanguageSelector/>
                        </div>
                        <div className="float-right">
                            <LocationSwitcher/>
                        </div>
                    </Col>
                </Row>
            </header>
        );
    }
}

export default Header;
