import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import {Translation} from 'react-i18next';
import './navigation.scss';

class Navigation extends React.Component {
    render() {
        const links = [
            {
                path: '/',
                icon: 'fas fa-tachometer-alt',
                title: 'pages.home.title'
            },
            {
                path: '/location',
                icon: 'fas fa-map',
                title: 'pages.your-location.title'
            }
        ];

        return (
            <Navbar className="pr-0 pl-0 mt-lg-5 mb-lg-5 pl-lg-2 w-100">
                <Nav className="flex-lg-column justify-content-center w-100" defaultActiveKey="/" activeKey={window.location.pathname}>
                    {links.map((link) => {
                        return (
                            <Nav.Link href={link.path}>
                                <Row>
                                    <Col lg="1" className="text-center"><i className={link.icon}/></Col>
                                    <Translation>
                                        {(t, {i18n}) =>
                                            <Col className="text-center text-lg-left">{t(link.title)}</Col>
                                        }
                                    </Translation>
                                </Row>
                            </Nav.Link>
                        )
                    })}
                    <Nav.Item className="border-top"/>
                    <Nav.Link href="/help">
                        <Translation>
                            {(t, {i18n}) =>
                                <Row>
                                    <Col lg="1" className="text-center"><i className="fas fa-question-circle"/></Col>
                                    <Col className="text-center text-lg-left">{t('pages.help.title')}</Col>
                                </Row>
                            }
                        </Translation>
                    </Nav.Link>
                </Nav>
            </Navbar>
        );
    }
}

export default Navigation;
