import React from 'react';
import Intro from '../Intro';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChatSection from '../sections/ChatSection';
import AppSection from '../sections/AppSection';

class HomePage extends React.Component {
    render() {
        return (
            <div>
                <Intro/>
                <Row>
                    <Col md="6">
                        <ChatSection/>
                    </Col>
                    <Col md="6">
                        <AppSection/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default HomePage;
