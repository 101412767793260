import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContactPerson from '../components/ContactPerson';

class HelpPage extends React.Component {
    render() {
        return (
            <section id="help">
                <Row className="text-center">
                    <Col sm className="pb-5">
                        <ContactPerson name="Benny Niedermeier" job="community-manager"
                                       email="benny@simplioffice.com" phone="+491747364187‬"
                                       username="benny.niedermeier" src="/images/persons/benny-niedermeier@1x.jpg"
                                       srcSet="/images/persons/benny-niedermeier@1x.jpg 1x, /images/persons/benny-niedermeier@2x.jpg 2x"/>
                    </Col>
                    <Col sm className="pb-5">
                        <ContactPerson name="Marvin Harde" job="partner-manager" email="marvin@simplioffice.com"
                                       phone="+491622952509‬" username="marvin.harde"
                                       src="/images/persons/marvin-harde@1x.jpg"
                                       srcSet="/images/persons/marvin-harde@1x.jpg 1x, /images/persons/marvin-harde@2x.jpg 2x"/>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default HelpPage;
