import React from 'react';
import {Translation} from 'react-i18next';
import Image from 'react-bootstrap/Image';
import ContainerDimensions from 'react-container-dimensions'
import {withCookies, Cookies} from 'react-cookie';
import {Locations} from '../Locations';
import {instanceOf} from 'prop-types';
import buildUrl from 'build-url';

class LocationPage extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        const {cookies} = props;

        let first = Locations[0].key;
        let key = cookies.get('location');
        let location = this.getLocationByKey(key || first);
        this.state = {location: location};
    }

    getLocationByKey(key) {
        let locations = Locations.filter(location => {
            return location.key === key
        });

        if (locations.length > 0) return locations[0];

        return null;
    }

    getMapURL(language, width, scale = 1) {
        width = LocationPage.getMapSize(width);
        let baseURL = 'https://maps.googleapis.com';
        return buildUrl(baseURL, {
            path: 'maps/api/staticmap',
            queryParams: {
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                language: language,
                scale: scale,
                size: width + 'x' + width,
                zoom: 15,
                format: 'png',
                maptype: 'roadmap',
                markers: 'size:normal|' + this.state.location.latitude + ',' + this.state.location.longitude
            }
        });
    }

    static getMapSize(width) {
        return width > 640 ? 640 : width;
    }

    render() {
        return (
            <div className="text-center">
                <h1 className="pb-5">
                    <Translation>{(t, {i18n}) => t('pages.your-location.title')}</Translation>
                </h1>
                <ContainerDimensions>
                    { ({width, height}) =>
                        <Translation>
                            {(t, {i18n}) =>
                                <Image className="rounded-circle" width={LocationPage.getMapSize(width)}
                                       height={LocationPage.getMapSize(width)}
                                       src={this.getMapURL(i18n.language, width)}
                                       srcSet={this.getMapURL(i18n.language, width) + ' 1x,' + this.getMapURL(i18n.language, width, 2) + ' 2x'}/>
                            }
                        </Translation>
                    }
                </ContainerDimensions>

            </div>
        );
    }
}

export default withCookies(LocationPage);
