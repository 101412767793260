import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import {Translation} from 'react-i18next';

class Loader extends React.Component {
    render() {
        return (
            <div className={'justify-content-center ' + this.props.className}>
                <Spinner animation="border" role="status" className="text-primary">
                    <Translation>{(t, {i18n}) => <span className="sr-only">{t('loading')}</span>}</Translation>
                </Spinner>
            </div>
        )
    }
}

export default Loader;
