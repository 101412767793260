import React from 'react';
import { Translation } from 'react-i18next';
import parse from 'html-react-parser';

class Footer extends React.Component {
    render() {
        return (
            <footer className="bg-dark text-light">
                <div className="p-5">
                    <Translation >
                        {
                            (t, { i18n }) => <span>&copy; Copyright 2014 - {(new Date().getFullYear())} | SimpliOffice Holding UG | {parse(t('copyright', { link: '<a href="https://interim-ventures.com" target="_blank" rel="noopener nonoreferrer">interim Ventures</a>'}))}</span>
                        }
                    </Translation>
                </div>
            </footer>
        );
    }
}

export default Footer;
