import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import Image from 'react-bootstrap/Image'
import {Translation} from 'react-i18next'

class LanguageSelector extends React.Component {
    render() {
        return (
            <Dropdown>
                <Translation>
                    {(t, {i18n}) =>
                        <Dropdown.Toggle variant="default" id="language-selector" className="text-light">
                            <Image roundedCircle src={t('flag')}
                                   srcSet={t('flag') + " 1x, " + t('flag-retina') + " 2x"}/>
                            <span className="ml-2 d-none d-lg-inline">{t('language')}</span>
                        </Dropdown.Toggle>
                    }
                </Translation>
                <Translation>
                    {(t, {i18n}) =>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="1" onClick={() => i18n.changeLanguage('en')}>
                                <Image roundedCircle src="/images/flags/flag-en@1x.jpg"
                                       srcSet="/images/flags/flag-en@1x.jpg 1x, /images/flags/flag-en@2x.jpg 2x"/>
                                <span className="pl-2">English</span>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="2" onClick={() => i18n.changeLanguage('de')}>
                                <Image roundedCircle src="/images/flags/flag-de@1x.jpg"
                                       srcSet="/images/flags/flag-de@1x.jpg 1x, /images/flags/flag-de@2x.jpg 2x"/>
                                <span className="pl-2">Deutsch</span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    }
                </Translation>
            </Dropdown>
        );
    }
}

export default LanguageSelector