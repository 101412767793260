import React from 'react';
import Image from 'react-bootstrap/Image';
import {Translation} from 'react-i18next';

class ContactPerson extends React.Component {
    render() {
        return (
            <div className={this.props.className}>
                <Image src={this.props.src} alt={this.props.name} roundedCircle srcSet={this.props.srcSet}/>
                <h4 className="mt-3">{this.props.name}</h4>
                <Translation>{(t, {i18n}) => <p>{t('job.' + this.props.job)}</p>}</Translation>
                <a className="rounded-circle bg-primary text-center text-light contact"
                   href={'mailto:' + this.props.email}>
                    <i className="fas fa-envelope"/>
                </a>
                <a className="rounded-circle bg-primary text-center text-light contact"
                   href={'tel:' + this.props.phone}>
                    <i className="fas fa-phone"/>
                </a>
                <a className="rounded-circle bg-primary text-center text-light contact" target="_blank"
                   href={'https://chat.simplioffice.com/direct/' + this.props.username}>
                    <i className="fas fa-comment-dots"/>
                </a>
            </div>
        );
    }
}

export default ContactPerson;
