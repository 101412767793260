import React from 'react';
import YouTube from 'react-youtube';
import Avatar from './Avatar';
import Loader from './Loader';
import {Translation} from 'react-i18next';
import {useKeycloak} from '@react-keycloak/web';
import './intro.scss';

const Intro = () => {
    const [profile, setProfile] = React.useState("");
    const [keycloak, initialized] = useKeycloak();

    const fetchUserProfile = async() => {
        // Here you can access all of keycloak methods and variables.
        // See https://www.keycloak.org/docs/latest/securing_apps/index.html#javascript-adapter-reference
        const profile = await keycloak.loadUserProfile();
        setProfile(profile);
    };

    if (initialized === true) {
        fetchUserProfile();
    }

    return (
        <section id="intro">
            <Translation>
                {(t, {i18n}) =>
                    <div className="text-center mb-5">
                        {profile
                            ? <Avatar profile={profile} size={100}/>
                            : <Loader className="d-flex"/>
                        }
                        {profile && <h1>{t('intro.greeting', {profile: profile})}</h1>}
                        <p>{t('intro.description')}</p>
                        <YouTube className="mw-100" videoId={'rETerUD5PDo'}/>
                    </div>
                }
            </Translation>
        </section>
    );
};

export default Intro;
